import Color from 'color';
import inViewport from 'in-viewport';
import PropTypes from 'prop-types';
import { Box, Flex, BoxVisible, Container } from '../style/basicStyle';
// React is a peer dependency
// eslint-disable-next-line import/no-unresolved, import/extensions
import React, { Component } from 'react';
// import styled from 'styled-components';
import styledSystem from '@emotion/styled';
import { resizeToCover, scrolledOverPercent } from './react-lazy-hero-utils';

const Cover = styledSystem.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const Root = styledSystem.div`
  position: relative;
  min-height: ${props => props.minHeight};
`;

const Img = styledSystem(Cover)`
  background-attachment: ${props => (props.isFixed ? 'fixed' : 'scroll')};
  background-image: url(${props => props.src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: ${props => (props.width ? `${props.width}px ${props.height}px` : 'cover')};
  opacity: ${props => (props.isVisible ? 1 : 0)};
  transition-duration: ${props => `${props.transitionDuration}ms`};
  transition-property: opacity;
  transition-timing-function: ${props => props.transitionTimingFunction};
`;
/*
const Overlay = styledSystem(Cover)`
  display: flex;
  justify-content: ${props => (props.isCentered ? 'center' : 'flex-start')};
  align-items: ${props => (props.isCentered ? 'center' : 'stretch')};
  text-align: ${props => (props.isCentered ? 'center' : 'left')};
  background-color: ${props =>
    Color(props.color)
      .alpha(props.opacity)
      .rgb()
      .string()};
`;
*/

const Overlay = styledSystem(Cover)`
  display: flex;
  justify-content: ${props => (props.isCentered ? 'center' : 'flex-start')};
  align-items: ${props => (props.isCentered ? 'center' : 'stretch')};
  text-align: ${props => (props.isCentered ? 'center' : 'left')};
  background-color: ${props =>
    Color(props.color)
      .alpha(props.opacity)
      .rgb()
      .string()};
`;

class LazyHero extends Component {
  constructor() {
    super();
    this.state = {
      backgroundPositionY: 'center',
      backgroundDimensions: null,
      heroDimensions: null,
      image: null,
      isInViewport: false
    };
    this.handleResize = this.handleResize.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.updatePosition = this.updatePosition.bind(this);
    this.updateSize = this.updateSize.bind(this);
  }

  componentDidMount() {
    inViewport(this.ref, () => this.setState({ isInViewport: true }));
    this.loadImage();

    if (this.props.parallaxOffset > 0) {
      window.addEventListener('scroll', this.handleScroll);
      window.addEventListener('resize', this.handleResize);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.imageSrc !== this.props.imageSrc) {
      this.loadImage();
    }
  }

  componentWillUnmount() {
    if (this.props.parallaxOffset > 0) {
      window.removeEventListener('scroll', this.handleScroll);
      window.removeEventListener('resize', this.handleResize);
    }
  }

  handleScroll() {
    this.updatePosition();
  }

  handleResize() {
    this.updateSize();
    this.updatePosition();
  }

  loadImage() {
    const image = new Image();
    image.src = this.props.imageSrc;
    image.onload = () => {
      this.setState({ image });

      if (this.props.parallaxOffset > 0) {
        this.updateSize();
        this.updatePosition();
      }
    };
  }

  updateSize() {
    if (!this.state.image) return;

    const heroDimensions = {
      height: this.ref.offsetHeight,
      width: this.ref.offsetWidth
    };

    const imageDimensions = {
      height: this.state.image.height,
      width: this.state.image.width
    };

    const resizedImage = resizeToCover(imageDimensions, heroDimensions);
    const initialVisibleImageHeight = resizedImage.height - this.props.parallaxOffset;

    const minHeight = initialVisibleImageHeight < heroDimensions.height ? resizedImage.height + heroDimensions.height - initialVisibleImageHeight : resizedImage.height;

    const finalHeight = minHeight + this.ref.offsetTop * 2;

    const backgroundDimensions = resizeToCover(imageDimensions, {
      height: finalHeight
    });
    this.setState({ backgroundDimensions, heroDimensions });
  }

  updatePosition() {
    if (!this.state.backgroundDimensions) return;
    const position =
      0 +
      this.ref.offsetTop -
      // Center image vertically
      this.state.backgroundDimensions.height / 2 +
      this.state.heroDimensions.height / 2 -
      this.props.parallaxOffset / 2 +
      // Apply scroll position
      this.props.parallaxOffset * scrolledOverPercent(this.ref);

    this.setState({ backgroundPositionY: `${Math.round(position)}px` });
  }

  render() {
    const { video } = this.props;
    const { backgroundDimensions, backgroundPositionY } = this.state;

    // dont think data-aos is doing anything below...
    return (
      <Root
        data-aos={this.props['data-aos']}
        css={this.props.css}
        ref={r => {
          this.ref = r;
        }}
        minHeight={this.props.minHeight}
        style={this.props.style}
      >
        {video && (
            <Box height={backgroundDimensions && backgroundDimensions.height} isFixed={this.props.isFixed || this.props.parallaxOffset > 0} style={{ backgroundPositionY }} width={backgroundDimensions && backgroundDimensions.width}>
              <video controls autoPlay muted>
                <source src={video} type="video/mp4" />
              </video>
            </Box>
        ) || (<Img
            height={backgroundDimensions && backgroundDimensions.height}
            isVisible={this.state.image && this.state.isInViewport}
            isFixed={this.props.isFixed || this.props.parallaxOffset > 0}
            src={this.props.imageSrc}
            style={{ backgroundPositionY }}
            transitionDuration={this.props.transitionDuration}
            transitionTimingFunction={this.props.transitionTimingFunction}
            width={backgroundDimensions && backgroundDimensions.width}
        />)}
        <Overlay color={this.props.color} isCentered={this.props.isCentered} opacity={this.props.opacity}>
          {this.props.children && <div>{this.props.children}</div>}
        </Overlay>
      </Root>
    );
  }
}

LazyHero.defaultProps = {
  video: undefined,
  children: undefined,
  css: undefined,
  color: '#fff',
  imageSrc: undefined,
  isCentered: true,
  isFixed: false,
  minHeight: '65vh',
  opacity: 0.8,
  parallaxOffset: 0,
  style: undefined,
  transitionDuration: 600,
  transitionTimingFunction: 'ease-in-out',
  'data-aos': undefined
};

LazyHero.propTypes = {
  'data-aos': PropTypes.string,
  video: PropTypes.string,
  children: PropTypes.node,
  css: PropTypes.string,
  color: PropTypes.string,
  imageSrc: PropTypes.string,
  isCentered: PropTypes.bool,
  isFixed: PropTypes.bool,
  minHeight: PropTypes.string,
  opacity: PropTypes.number,
  parallaxOffset: PropTypes.number,
  style: PropTypes.objectOf(PropTypes.any),
  transitionDuration: PropTypes.number,
  transitionTimingFunction: PropTypes.string
};

export default LazyHero;
